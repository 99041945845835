import { useEffect, useState, useRef } from 'react';
import './App.scss';
import Mouth from '../../components/Mouth/Mouth';
import { scroller } from 'react-scroll';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import CarouselProducts from "../../components/CarouselProducts/CarouselProducts";

function App() {
  const mobileVideo = useRef();
  const desktopVideo = useRef();

  const [windowHeight, setWindowHeight] = useState();
  const [windowWidth, setWindowWidth] = useState();
  const [scrollPos, setScrollPos] = useState(0);
  const [pupilOffset, setPupilOffset] = useState(0);

  const [buttonHover, setButtonHover] = useState(false);
  const [play, setPlay] = useState(false);

  useEffect(()=>{
    console.log(windowWidth);

    if (windowWidth > 500) {
      mobileVideo.current.style.display = 'none';
      desktopVideo.current.style.display = 'block';
    } else {
      mobileVideo.current.style.display = 'block';
      desktopVideo.current.style.display = 'none';
    }
  }, [windowWidth])

  useEffect(()=>{

    setWindowHeight(window.innerHeight)
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', (e)=>{
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    })

    document.addEventListener('scroll', (e)=>{
      setScrollPos(e.target.scrollingElement.scrollTop)
    })

  }, []);

  useEffect(()=>{

    let textRect = document.querySelector('.level_up .right').getBoundingClientRect();
    let eyeRect = document.querySelector('#eye').getBoundingClientRect();

    if (textRect.bottom < windowHeight && textRect.top > 0) {
      document.getElementById('mouth_inner').classList.add('active');
      document.querySelector('.level_up .right').classList.add('active');
    } else {
      document.getElementById('mouth_inner').classList.remove('active');
      document.querySelector('.level_up .right').classList.remove('active');
    }

    if (eyeRect.bottom < windowHeight && eyeRect.top > 0) {
      document.querySelector('#eye').classList.remove('hide');

      let wH = windowHeight-eyeRect.height;
      let top = eyeRect.top;

      setPupilOffset((90 * top) / wH);

    } else {
      document.querySelector('#eye').classList.add('hide');
    }

  }, [scrollPos, windowHeight])

  return (
    <div className="app">
      <Header />
      <div className='header_home'> 
        <div className='head_container'>
          <a href="https://www.backtothebreak.it" target='_blank'>
          <img ref={desktopVideo} src="/img/header_back-to-the-break.png" />
          <img ref={mobileVideo} src="/img/header-mobile_back-to-the-break.png" />
          </a>
        </div>
        
      
      </div>
      <section className="level_up">
        <div className="content">
          <div className="left">
            <h2>LEVEL UP <br/> WITH <br/> MR. DAY!</h2>
            <button onClick={()=>{scroller.scrollTo('game', {
              duration: 1000,
              smooth: 'easeOutQuad'
            })}}>SCOPRI ORA!</button>
          </div>
          <div className="right">
            <p>Le nuove merende Mr. Day sono la combo perfetta tra energia e gusto.
              Sono la <b>pausa che non lagga</b>, ma che anzi ti ricarica e rende la tua
              merenda golosa e divertente</p>
            <p>Un vero e proprio Level Up dei tuoi momenti di Break!</p>
          </div>
        </div>
        
        <Mouth/>

      </section>
      <div className="eye_mid_section">
        <svg className="orange_bottom" width="1428" height="307" viewBox="0 0 1428 307" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M0.970703 0.493958C140.989 55.1177 305.545 104.287 483.859 142.21C885.095 227.545 1245.87 232.521 1427.15 167.423V0.493973L0.970703 0.493958Z" fill="#F49D00"/>
            <g id="eye" className="hide">
              <path d="M958.556 215.201C956.765 234.898 949.174 253.621 936.741 269.004C924.309 284.386 907.595 295.737 888.712 301.62C869.829 307.503 849.626 307.654 830.657 302.055C811.688 296.456 794.805 285.358 782.144 270.164C769.482 254.969 761.611 236.362 759.524 216.694C757.438 197.026 761.231 177.182 770.423 159.669C779.616 142.157 793.794 127.764 811.167 118.31C828.539 108.856 848.324 104.766 868.021 106.557C881.099 107.746 893.815 111.499 905.443 117.603C917.071 123.706 927.383 132.04 935.79 142.129C944.196 152.217 950.534 163.863 954.44 176.401C958.347 188.938 959.745 202.123 958.556 215.201" fill="white"/>
              <g id="pupil" style={{transform: `translateY(-${pupilOffset}px)`}}>
                <path d="M841.996 205.693C835.158 205.078 828.27 206.064 821.88 208.575C813.027 212.288 805.492 218.57 800.245 226.608C795.19 234.822 792.95 244.46 793.866 254.061C794.782 263.662 798.803 272.703 805.319 279.813C811.835 286.923 820.492 291.715 829.977 293.462C839.462 295.21 849.258 293.817 857.881 289.496C866.503 285.175 873.482 278.161 877.759 269.516C882.036 260.872 883.378 251.069 881.582 241.593C879.786 232.117 874.95 223.485 867.806 217.005C860.663 210.525 851.602 206.55 841.996 205.684" fill="#36A9E1"/>
                <path d="M840.165 225.881C836.449 225.548 832.707 226.087 829.237 227.456C824.428 229.473 820.333 232.885 817.484 237.253C814.737 241.714 813.52 246.948 814.017 252.164C814.513 257.379 816.696 262.29 820.235 266.152C823.774 270.015 828.476 272.619 833.628 273.569C838.779 274.519 844.101 273.763 848.784 271.417C853.468 269.07 857.26 265.261 859.584 260.566C861.908 255.871 862.638 250.547 861.663 245.399C860.689 240.252 858.062 235.563 854.183 232.042C850.303 228.522 845.382 226.362 840.165 225.89" fill="#1D1D1B"/>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <section className="new_patch" style={{backgroundImage: 'url(/img/star.svg)'}}>
        <div className="content">
          <div className="left">
            <h2>READY <br/> FOR THE <br/> NEW <br/> PATCH?</h2>
          </div>
          <div className="right">
            <p>Gioca e <span>scopri la</span> novità!</p>
          </div>
        </div>

      </section>
      <section id="game" className="game" >
        <div className="content">
          {play ?
            <iframe title="game" src="/game/index.html" height="804" width="804">game not available</iframe>
          :
            <button className="play_button" onClick={()=>setPlay(true)} onMouseOver={()=>setButtonHover(true)} onMouseLeave={()=>setButtonHover(false)}>
              {buttonHover ?
                <img className="hover" src="/img/button_2.svg" alt="GO button" />
              :
                <img className="nohover" src="/img/button_1.svg" alt="GO button active" />
              }
            </button>
          }
        </div>
      </section>
      <div className="blue_mid_section">
        <img src="/img/blue_bottom.svg" alt="" />
      </div>
      <CarouselProducts title="SCEGLI LA TUA<br/>NUOVA SKIN" slug="Home" />
      <Footer/>
    </div>
  );
}

export default App;


